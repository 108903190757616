.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Asegura que el contenido se distribuya */
  min-height: 100vh; /* Asegura que el .App llene al menos toda la ventana del navegador */
  align-items: center;
  text-align: center;
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0071bc; /* Color de la capa */
  opacity: 0.5; /* Ajusta la opacidad según necesites */
}

.logo {
  width: 200px; /* Ajusta esto según el tamaño que desees */
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.content {
  margin-top: 250px;
  display: flex;
  flex-direction: row; /* Disposición en fila para pantallas grandes */
  justify-content: space-around;
  align-items: flex-start; /* Alinea elementos al inicio verticalmente */
  width: 80%;
  max-width: 1200px;
}

.left-content {
  padding: 50px;
  display: flex;
  flex-direction: column; /* Los elementos internos se organizan en columna */
  max-width: 100%; /* Ajusta este valor según sea necesario */
}

.text-section,
.call-to-action {
  width: 100%; /* Asegura que cada sección utilice todo el ancho del contenedor .left-content */
}

.iframe-container iframe {
  max-width: 100%;
  padding: 50px;
  width: 500px; /* Establece el ancho al 100% del contenedor */
  height: 1000px; /* Mantén la altura deseada */
  border: none;
  z-index: 2;
}

.call-to-action {
  max-width: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.phone-number {
  color: white;
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 10px; /* Añade un margen entre los íconos si es necesario */
}

.phone-icon {
  width: 1.5em;
  height: 1.5em;
}

.footer {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  color: white;
  text-align: center;
}

.footer a {
  color: white; /* Asegura que los enlaces sean blancos */
  text-decoration: none; /* Elimina el subrayado de los enlaces */
}

.footer p {
  margin: 5px 0; /* Ajusta el margen según sea necesario */
}

.content,
.footer {
  z-index: 2; /* Asegura que el contenido y el footer estén sobre el fondo */
  width: 100%; /* Ajusta según sea necesario para tu diseño */
}

.cc-window {
  border-radius: 10px;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center; /* Centra los elementos horizontalmente */
    width: 100%;
    margin-top: 150px; /* Ajusta el margen superior según sea necesario */
  }

  .left-content,
  .iframe-container {
    width: 100%; /* Ocupa el 100% del ancho en pantallas pequeñas */
    align-items: center; /* Asegura que los contenidos internos también estén centrados */
    display: flex;
    flex-direction: column;
    max-width: 80%;
  }

  .iframe-container iframe {
    margin-left: 20%;
    width: 90%; /* Ajusta el ancho del iframe al 90% del contenedor */
    height: 1000px; /* Ajusta la altura automáticamente */
    padding: 20px; /* Reduce el padding para pantallas pequeñas */
  }

  .logo {
    top: 5%; /* Ajusta la posición superior del logo */
    width: 150px; /* Reduce el tamaño del logo para pantallas más pequeñas */
  }
}
